<template>
  <div class="p-component-overlay" v-if="loading">
    <i class="pi pi-spin pi-spinner" style="fontSize: 2rem"></i>
  </div>
  <div class="p-col-12">
    <div class="intro-y box p-5 mt-5">
      <Toast />
      <Fieldset legend="Información del cliente" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NitCliente">Nit del cliente</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdClie" v-model="localOrder.Encabezado.IdClie" type="text"/>
<!--            <AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="NitCliente" @item-select="watchSelectNitCliente()" :dropdown="true"  v-model="localOrder.Encabezado.NitCliente" :suggestions="optionsTercCliente" @complete="hanldeSearchInputTercCliente($event)" field="NomRepLeg"/>-->
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NomCliente">Nombre Cliente</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NomCliente" v-model="localOrder.Encabezado.NomCliente" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="IdAgencia">Código de la agencia</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdAgencia" v-model="localOrder.Encabezado.IdAgencia" :filter="true" :options="optionsIdAgencia" optionLabel="Agencia" placeholder="Select" />
            <!--<AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="IdAgencia" :dropdown="true"  v-model="localOrder.Encabezado.IdAgencia" :suggestions="optionsIdAgencia" @complete="hanldeSearchInputIdAgencia($event)" field="Agencia"/>-->
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="FacturarA">Facturar a</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="FacturarA" v-model="localOrder.Encabezado.FacturarA" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <Button type="button" label="Search" icon="pi pi-search" :loading="loadingSearch[0]" @click="hanldeSearchInputTercCliente()"></Button>
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Remitente" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NitRemitente">Identificación del remitente</label>
            <!--itemDetalle.NitRemitente-->
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdTercero" v-model="localOrder.Encabezado.IdTercero" type="text"/>
            <!--<AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" @item-select="watchSelectNitRemitente(key)" id="NitRemitente" :dropdown="true"  v-model="detalleItem.NitRemitente" :suggestions="optionsTercero" @complete="hanldeSearchInputTercero($event)" field="RazonSocial"/>-->
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NomRemitente">Nombre Remitente</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NomRemitente" v-model="localOrder.Encabezado.NomRemitente" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="SedeRemitente">Nombre de la sede del remitente</label>
            <!--itemDetalle.SedeRemitente-->
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="SedeRemitente" v-model="detalleItem.SedeRemitente" :filter="true" :options="optionsSedeRemitente" optionLabel="NomSede" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NomContacto">Nombre del contacto en el origen</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NomContacto" v-model="localOrder.Encabezado.NomContacto" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TelContacto">Teléfono de contacto del origen</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TelContacto" v-model="localOrder.Encabezado.TelContacto" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="emailContac">Email de contacto origen</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="emailContac" v-model="localOrder.Encabezado.emailContac" type="email"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <Button type="button" label="Search" icon="pi pi-search" :loading="loadingSearch[1]" @click="hanldeSearchInputTercero(1)"></Button>
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Destinatario" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <!--itemDetalle.NitDestinatario-->
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NitDestinatario">Identificación del destinatario</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdTercero1" v-model="localOrder.Encabezado.IdTercero1" type="text"/>
            <!--<AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" @item-select="watchSelectNitDestinatario(key)" id="NitDestinatario" :dropdown="true"  v-model="detalleItem.NitDestinatario" :suggestions="optionsTercero" @complete="hanldeSearchInputTercero($event)" field="RazonSocial"/>-->
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NomDestinatario">Nombre Destinatario</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NomDestinatario" v-model="localOrder.Encabezado.NomDestinatario" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <!-- itemDetalle.SedeDestinatario-->
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="SedeDestinatario">Nombre de la sede de destinatario</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="SedeDestinatario" v-model="detalleItem.SedeDestinatario" :filter="true" :options="optionsSedeDestinatario" optionLabel="NomSede" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NomContactoDest">Nombre del contacto en el destino</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NomContactoDest" v-model="localOrder.Encabezado.NomContactoDest" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TelContactoDest">Teléfono de contacto del destino</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TelContactoDest" v-model="localOrder.Encabezado.TelContactoDest" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="emailContacDest">Email de contacto destino</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="emailContacDest" v-model="localOrder.Encabezado.emailContacDest" type="email"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <Button type="button" label="Search" icon="pi pi-search" :loading="loadingSearch[2]" @click="hanldeSearchInputTercero(2)"></Button>
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Pedido" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-2">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Fecha">Fecha actual del aplicativo</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Fecha" v-model="localOrder.Encabezado.Fecha" type="datetime-local" step="1"/>
          </div>
          <div class="md:w-1/3 px-2">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="DiasVigencia">Días de vigencia de la orden</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="DiasVigencia" v-model="localOrder.Encabezado.DiasVigencia" type="number"/>
          </div>
          <div class="md:w-1/3 px-2">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="FecDespacho">Fecha del despacho</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="FecDespacho" v-model="localOrder.Encabezado.FecDespacho" type="datetime-local" step="1" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-2">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="FecEntrega">Fecha programada de entrega</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="FecEntrega" v-model="localOrder.Encabezado.FecEntrega" type="datetime-local" step="1"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Modalidad">Modalidad</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Modalidad" v-model="localOrder.Encabezado.Modalidad" :filter="true" :options="optionsModalidad" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TipoTransporte">Tipo Transporte</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TipoTransporte" v-model="localOrder.Encabezado.TipoTransporte" :filter="true" :options="optionsTipoTransporte" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="CodRuta">Código de la ruta</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="CodRuta" v-model="localOrder.Encabezado.CodRuta" type="text"/>
            <!--<AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="IdRuta" @item-select="watchSelectIdRuta()" :dropdown="true"  v-model="localOrder.Encabezado.IdRuta" :suggestions="optionsIdRuta" @complete="hanldeSearchInputIdRuta($event)" field="Ruta"/>-->
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NomRuta">Ruta</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NomRuta" v-model="localOrder.Encabezado.NomRuta" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <Button type="button" label="Search" icon="pi pi-search" :loading="loadingSearch[5]" @click="hanldeSearchInputIdRuta()"></Button>
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Detalle de mercancías" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="IdMercancia">Código de mercancía</label>
            <AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="IdMercancia" :dropdown="true" @item-select="watchSelectIdMercancia()" v-model="detalleItem.IdMercancia" :suggestions="optionsIdMercancia" @complete="hanldeSearchInputIdMercancia($event)" field="DescripMcia"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="idDescripMcias">Nombre del producto</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="idDescripMcias" v-model="detalleItem.DescripMcias" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="idCantidad">Cantidad de unidades</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="idCantidad" v-model="detalleItem.Cantidad" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="idCases">Cantidad de cases</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="idCases" v-model="detalleItem.Cases" type="number"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="idCajas">Cantidad de cajas</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="idCajas" v-model="detalleItem.Cajas" type="number"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Palets">Cantidad de palets</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Palets" v-model="detalleItem.Palets" type="number"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Peso">Peso en kilos</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Peso" v-model="detalleItem.Peso" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="UndMed">Unidad de medida</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="UndMed" v-model="detalleItem.UndMed" :filter="true" :options="optionsUndMed" optionLabel="Unidad" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="CiudadOrigen">Ciudad de origen</label>
            <AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="CiudadOrigen" :dropdown="true"  v-model="detalleItem.CiudadOrigen" :suggestions="optionsCiudad" @complete="hanldeSearchInputCiudad($event)" field="Localidad"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="CiudadDestino">Ciudad de destino</label>
            <AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="CiudadDestino" :dropdown="true"  v-model="detalleItem.CiudadDestino" :suggestions="optionsCiudad" @complete="hanldeSearchInputCiudad($event)" field="Localidad"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TarifaCliente">Tarifa que se va a cobrar al cliente</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TarifaCliente" v-model="detalleItem.TarifaCliente" type="text" onkeyup="formatAmount(this)"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="UnidadCliente">Unidad de medida usada para calcular el valor a
              cobrar al cliente</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="UnidadCliente" v-model="detalleItem.UnidadCliente" :filter="true" :options="optionsUndTarifaPago" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TarifaTabla">Tarifa tabla</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TarifaTabla" v-model="detalleItem.TarifaTabla" type="text" onkeyup="formatAmount(this)"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TarifaPago">Tarifa que se va a pagar al transportador</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TarifaPago" v-model="detalleItem.TarifaPago" type="text" onkeyup="formatAmount(this)"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="UndTarifaPago">Unidad de medida usada para calcular el valor
              de pago</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="UndTarifaPago" v-model="detalleItem.UndTarifaPago" :filter="true" :options="optionsUndTarifaPago" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="idDirOrigen">Dirección de origen</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="idDirOrigen" v-model="detalleItem.DirOrigen" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="idDirDestino">Dirección de destino</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="idDirDestino" v-model="detalleItem.DirDestino" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrDeclarado">Valor declarado de la mercancía</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrDeclarado" v-model="detalleItem.VrDeclarado" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TarifSeguro">Tarifa del seguro</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TarifSeguro" v-model="detalleItem.TarifSeguro" type="text" onkeyup="formatAmount(this)"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Referencia1">Campo referencia 1</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Referencia1" v-model="detalleItem.Referencia1" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Referencia2">Campo referencia 2</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Referencia2" v-model="detalleItem.Referencia2" type="text"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Referencia3">Campo referencia 3</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Referencia3" v-model="detalleItem.Referencia3" type="text"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="dmsLargo">Dimensiones de largo</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="dmsLargo" v-model="detalleItem.dmsLargo" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="dmsAncho">Dimensiones de ancho</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="dmsAncho" v-model="detalleItem.dmsAncho" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="dmsAlto">Dimensiones de alto</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="dmsAlto" v-model="detalleItem.dmsAlto" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Volumen">Valor del volumen transportado</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Volumen" v-model="detalleItem.Volumen" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="UndVol">Unidad de medida usada para calcular el valor
              por volumen</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="UndVol" v-model="detalleItem.UndVol" :filter="true" :options="optionsUndVol" optionLabel="name" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Embalajes">Tipo de embalaje</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Embalajes" v-model="detalleItem.Embalajes" :filter="true" :options="optionsEmbalajes" optionLabel="Unidad" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="IdEmpaque">Código del empaque</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdEmpaque" v-model="detalleItem.IdEmpaque" :filter="true" :options="optionsIdEmpaque" optionLabel="Empaque" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="IdNaturaleza">Código de la naturaleza de la carga</label>
            <Dropdown class="w-full border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdNaturaleza" v-model="detalleItem.IdNaturaleza" :filter="true" :options="optionsIdNaturaleza" optionLabel="Natlzaprod" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="IdManejo">Código de embalaje</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdManejo" v-model="detalleItem.IdManejo" :filter="true" :options="optionsIdManejo" optionLabel="Manejo" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Riesgos">Tipo de riesgo</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Riesgos" v-model="detalleItem.Riesgos" :filter="true" :options="optionsRiesgos" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="idCdRango">Código del rango</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="idCdRango" v-model="detalleItem.CdRango" :filter="true" :options="optionsCdRango" optionLabel="DescripRango" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="DocCliente">Documento del cliente</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="DocCliente" v-model="detalleItem.DocCliente" type="text"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="idContenedor1">Código del contenedor 1</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="idContenedor1" v-model="detalleItem.Contenedor1" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="idContenedor2">Código del contenedor 2</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="idContenedor2" v-model="detalleItem.Contenedor2" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Tipo_Servicio">Descripción del tipo de servicio</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Tipo_Servicio" v-model="detalleItem.Tipo_Servicio" :filter="true" :options="optionsTipo_Servicio" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="CdTipoVehic">Código del tipo de vehículo</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="CdTipoVehic" v-model="localOrder.Encabezado.CdTipoVehic" :filter="true" :options="optionsCdTipoVehic" optionLabel="TipoVehiculo" placeholder="Select" />
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5" :key = "key"  v-for="(itemConcepto,key) in localOrder.Concepto">
      <Fieldset :legend="`Concepto Nº ${key+1}`" :toggleable="true" :collapsed="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="CodigoConcepto">Código del concepto</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="CodigoConcepto" :change="watchSelectCodigoConcepto(key)" v-model="itemConcepto.CodigoConcepto" :filter="true" :options="optionsCodigoConcepto" optionLabel="Concepto" placeholder="Select" />
          </div>
          <div class="md:w-1/2 px-3">
          </div>
        </div>
        <div class="grid grid-cols-1 mb-2">
          <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Descripcion">Descripción del concepto</label>
          <Textarea class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Descripcion" v-model="itemConcepto.Descripcion" rows="4"/>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Cantidad">Cantidad a transportar</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Cantidad" v-model="itemConcepto.Cantidad" type="number"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrUnitario">Valor del servicio por cada unidad</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrUnitario" v-model="itemConcepto.VrUnitario" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Rubro">Rubro</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Rubro" :change="watchSelectRubro()"  v-model="itemConcepto.Rubro" :filter="true" :options="optionsRubro" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TipoConcepto">Tipo de Concepto</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TipoConcepto" v-model="itemConcepto.TipoConcepto" :filter="true" :options="optionsTipoConcepto" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Nit">Nit del cliente al que se le van a cargar los conceptos</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdTercero3" v-model="localOrder.Encabezado.IdTercero3" type="text"/>
            <!--<AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="Nit" :dropdown="true"  v-model="itemConcepto.Nit" :suggestions="optionsTercero" @complete="hanldeSearchInputTercero($event)" field="RazonSocial"/>-->
          </div>
          <div class="md:w-1/3 px-3">
            <Button type="button" label="Search" icon="pi pi-search" :loading="loadingSearch[4]" @click="hanldeSearchInputTercero(4)"></Button>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NomNit">Nombre del cliente al que se le van a cargar los conceptos</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NomNit" v-model="localOrder.Encabezado.NomNit" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TipoEscolta">Tipo de Escolta</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TipoEscolta" v-model="itemConcepto.TipoEscolta" :filter="true" :options="optionsTipoEscolta" optionLabel="name" placeholder="Select" />
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Totales" :toggleable="true" :collapsed="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrCargue">Valor del cargue</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrCargue" v-model="localOrder.Encabezado.VrCargue" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrDesCargue">Valor del descargue</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrDesCargue" v-model="localOrder.Encabezado.VrDesCargue" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrEscolta">Valor de los escoltas</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrEscolta" v-model="localOrder.Encabezado.VrEscolta" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrDevolucionContdor">Valor de devolución del contenedor</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrDevolucionContdor" v-model="localOrder.Encabezado.VrDevolucionContdor" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrTraUrbano">Valor del transporte urbano</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrTraUrbano" v-model="localOrder.Encabezado.VrTraUrbano" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrEmbalajes">Valor de los embalajes</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrEmbalajes" v-model="localOrder.Encabezado.VrEmbalajes" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrCargos">Valor unitario de los conceptos</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrCargos" v-model="localOrder.Encabezado.VrCargos" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrDctos">Valor de descuentos</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrDctos" v-model="localOrder.Encabezado.VrDctos" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Moneda">Tipo de moneda a usar</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Moneda" v-model="localOrder.Encabezado.Moneda" :filter="true" :options="optionsMoneda" optionLabel="Mneda" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrTasa">Valor de la tasa de cambio</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrTasa" v-model="localOrder.Encabezado.VrTasa" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TipoTarifa">Tipo Tarifa</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TipoTarifa" v-model="localOrder.Encabezado.TipoTarifa" :filter="true" :options="optionsTipoTarifa" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Vigencia">Cantidad de días de vigencia</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Vigencia" v-model="localOrder.Encabezado.Vigencia" :filter="true" :options="optionsVigencia" placeholder="Select" />
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Información Comercial" :toggleable="true" :collapsed="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="PolizaEspecifica">Indica si se tiene póliza especifica</label>
            <div id="PolizaEspecifica" class="w-full border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded">
              <input type="radio" :value="true" v-model="localOrder.Encabezado.PolizaEspecifica"> Sí
              <input type="radio" :value="false" v-model="localOrder.Encabezado.PolizaEspecifica"> No
            </div>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NumPolizaEsp">Numero de la póliza de seguro</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NumPolizaEsp" v-model="localOrder.Encabezado.NumPolizaEsp" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="FecVencePol">Fecha de vencimiento de la póliza</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="FecVencePol" v-model="localOrder.Encabezado.FecVencePol" type="datetime-local" step="1"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NitCiaPoliza">Nit de la empresa aseguradora</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdTercero2" v-model="localOrder.Encabezado.IdTercero2" type="text"/>
            <!--<AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="NitCiaPoliza" :dropdown="true"  v-model="localOrder.Encabezado.NitCiaPoliza" :suggestions="optionsTercero" @complete="hanldeSearchInputTercero($event)" field="RazonSocial"/>-->
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NomCiaPoliza">Nombre de la empresa aseguradora</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NomCiaPoliza" v-model="localOrder.Encabezado.NomCiaPoliza" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="VrLimiteDesp">Valor límite del despacho</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="VrLimiteDesp" v-model="localOrder.Encabezado.VrLimiteDesp" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <Button type="button" label="Search" icon="pi pi-search" :loading="loadingSearch[3]" @click="hanldeSearchInputTercero(3)"></Button>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="NitSIA">Nit de la empresa SIA</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="NitSIA" v-model="localOrder.Encabezado.NitSIA" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TeleContactoSIA">Teléfono de contacto SIA</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TeleContactoSIA" v-model="localOrder.Encabezado.TeleContactoSIA" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="ContactoSIA">Nombre del contacto SIA</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="ContactoSIA" v-model="localOrder.Encabezado.ContactoSIA" type="text"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Embarque">Tipo de Embarque</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Embarque" v-model="localOrder.Encabezado.Embarque" :filter="true" :options="optionsEmbarque" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="IdVend">Identificación del vendedor</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="IdVend" v-model="localOrder.Encabezado.IdVend" :filter="true" :options="optionsIdVend" optionLabel="nomVend.RazonSocial" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="CdTipCarga">Código del tipo de carga</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="CdTipCarga" v-model="localOrder.Encabezado.CdTipCarga" :filter="true" :options="optionsCdTipCarga" optionLabel="TipoCarga" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TarifaComision">Tarifa de comisión del vendedor</label>
            <AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="TarifaComision" :dropdown="true"  v-model="localOrder.Encabezado.TarifaComision" :suggestions="optionsTarifaComision" @complete="hanldeSearchInputTarifaComision($event)" field="Nombre"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Seguros">Tipo de seguro</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Seguros" v-model="localOrder.Encabezado.Seguros" :filter="true" :options="optionsSeguros" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Cargue">Lugar de cargue</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Cargue" v-model="localOrder.Encabezado.Cargue" :filter="true" :options="optionsCargue"  placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Descargue">Lugar de descargue</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="Descargue" v-model="localOrder.Encabezado.Descargue" :filter="true" :options="optionsCargue" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TipoRuta">Tipo Ruta</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TipoRuta" v-model="localOrder.Encabezado.TipoRuta" :filter="true" :options="optionsTipoRuta" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="MargenFalt">Margen de tolerancia en faltantes</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="MargenFalt" v-model="localOrder.Encabezado.MargenFalt" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TipoMargen">Tipo Margen</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TipoMargen" v-model="localOrder.Encabezado.TipoMargen" :filter="true" :options="optionsTipoMargen" placeholder="Select" />
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="UndCalcFalt">Unidad usada por el cliente para calcular
              los faltantes en la tolerancia</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="UndCalcFalt" v-model="localOrder.Encabezado.UndCalcFalt" :filter="true" :options="optionsUndCalcFalt" placeholder="Select" />
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TarifFaltPago">Tarifa usada para calcular los faltantes al
              momento de pagar en la tolerancia</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TarifFaltPago" v-model="localOrder.Encabezado.TarifFaltPago" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="TarifFaltCobro">Tarifa usada por el cliente para calcular
              los faltantes en la tolerancia</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="TarifFaltCobro" v-model="localOrder.Encabezado.TarifFaltCobro" type="text" onkeypress="return filterFloat(event,this);"/>
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="General" :toggleable="true" :collapsed="true">
        <div class="grid grid-cols-1 mb-2">
          <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="Observacion">Observación sobre el pedido</label>
          <Textarea class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-2 rounded" id="Observacion" v-model="localOrder.Encabezado.Observacion" rows="4"/>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="DevContenedor">Devolución de contenedor</label>
            <div id="DevConteneFdor" class="w-full border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded">
              <input type="radio" :value="true" v-model="localOrder.Encabezado.DevContenedor"> Sí
              <input type="radio" :value="false" v-model="localOrder.Encabezado.DevContenedor"> No
            </div>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="PatioCont">Nombre del patio de devolución del
              contenedor</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="PatioCont" v-model="localOrder.Encabezado.PatioCont" type="text"/>
          </div>
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="CiudadDevContenedor">Ciudad de devolución del contenedor</label>
            <AutoComplete class="w-full border-gray-200 text-xs rounded" placeholder="Search" id="CiudadDevContenedor" :dropdown="true"  v-model="localOrder.Encabezado.CiudadDevContenedor" :suggestions="optionsCiudad" @complete="hanldeSearchInputCiudad($event)" field="Localidad"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/3 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="CdTipoEsc">Código de tipo de escolta</label>
            <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="CdTipoEsc" v-model="localOrder.Encabezado.CdTipoEsc" :filter="true" :options="optionsCdTipoEsc" optionLabel="TipoEscolta" placeholder="Select" />
          </div>
        </div>
      </Fieldset>
    </div>
  </div>
  <div class="p-col-12">
    <!--
    <div class="intro-y box p-5 mt-5" :key = "key"  v-for="(itemDetalle,key) in localOrder.Detalle">
      <Fieldset :legend="`Detalle Nº ${key+1}`" :toggleable="true">

      </Fieldset>
    </div>-->
    <div class="text-left mt-5" v-if="button">
      <button class="btn btn-primary w-24 mr-1 mb-2" @click="saveOrder">Guardar</button>
      <button class="btn btn-success w-24 mr-1 mb-2" v-if="method == 'put'" @click="saveSyscom">Syscom</button>
    </div>
  </div>
</template>

<script>
import AcceptedOrder from "@/service/AcceptedOrder";

export default {
  props: {
    order: {
      type: Object,
      default() {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    },
    method: {
      type: String,
      default: 'post'
    }
  },
  data() {
    return{
      loadingSearch: [
          false,
          false,
          false,
          false,
          false,
          false
      ],
      CdCms: "",
      detalleItem: {
        "Cajas":null,
        "Cantidad":null,
        "Cases":null,
        "CdRango":null,
        "CiudadDestino":null,
        "CiudadOrigen":null,
        "Contenedor1":null,
        "Contenedor2":null,
        "DescripMcias":null,
        "DirDestino":null,
        "DirOrigen":null,
        "DocCliente":null,
        "Embalajes":null,
        "IdEmpaque":null,
        "IdManejo":null,
        "IdMercancia":null,
        "IdNaturaleza":null,
        "NitDestinatario":null,
        "NitRemitente":null,
        "Palets":null,
        "Peso":null,
        "Referencia1":null,
        "Referencia2":null,
        "Referencia3":null,
        "Riesgos": "NORMAL",
        "SedeDestinatario":null,
        "SedeRemitente":null,
        "TarifSeguro": "0",
        "TarifaCliente":null,
        "TarifaPago":null,
        "TarifaTabla":null,
        "Tipo_Servicio": "EXPRESO",
        "UndMed": {
          "UndMed": "kg",
          "Unidad": "kilogramo"
        },
        "UndTarifaPago": "UNIDADES",
        "UndVol": {
          code: "m3",
          name: "METRO CÚBICO"
        },
        "UnidadCliente": "UNIDADES",
        "Volumen":0,
        "VrDeclarado":null,
        "dmsAlto":0,
        "dmsAncho":0,
        "dmsLargo":0,
        "solicitud":null,
        "tipoEntrega": "otros",
        "idEntrega": null,
        "solicitudServicio": null
      },
      localOrder: {
        Concepto: [],
        Detalle: [],
        Encabezado: {
          "Cargue": "EMPRESA",
          "CdTipCarga": {
            IdTipCarga: "0",
            TipoCarga: "PREDETERMINADO"
          },
          "CdTipoEsc":null,
          "CdTipoVehic":null,
          "CiudadDevContenedor": {
            IdLocal: "11001",
            Localidad: "BOGOTA D.C."
          },
          "ContactoSIA":null,
          "Descargue": "EMPRESA",
          "DevContenedor":false,
          "DiasVigencia":null,
          "Embarque": "SUELTA",
          "FecDespacho":null,
          "FecEntrega":null,
          "FecVencePol":null,
          "Fecha":null,
          "IdAgencia":null,
          "IdRuta": "0000",
          "IdVend":null,
          "MargenFalt": 0,
          "Modalidad": "ESTANDAR",
          "Moneda": {
            idMneda: "COP",
            Mneda: "Peso colombiano"
          },
          "NitCiaPoliza":null,
          "NitCliente":null,
          "nombre_cliente":null,
          "NitSIA":null,
          "NomContacto":null,
          "NomContactoDest":null,
          "NumPolizaEsp":null,
          "Observacion":null,
          "PatioCont":null,
          "PolizaEspecifica":false,
          "Seguros": "EMPRESA",
          "TarifFaltCobro":null,
          "TarifFaltPago":null,
          "TarifaComision":null,
          "TelContacto":null,
          "TelContactoDest":null,
          "TeleContactoSIA":null,
          "TipoMargen": "UNIDAD",
          "TipoRuta": "NACIONAL",
          "TipoTarifa": "PEDIDO",
          "TipoTransporte":null,
          "UndCalcFalt": "PESO",
          "Vigencia": "NORMAL",
          "VrCargos":0,
          "VrCargue":0,
          "VrDctos":0,
          "VrDesCargue":0,
          "VrDevolucionContdor":0,
          "VrEmbalajes":0,
          "VrEscolta":0,
          "VrLimiteDesp":null,
          "VrTasa":null,
          "VrTraUrbano":0,
          "emailContac":null,
          "emailContacDest":null,
          NomCliente: null,
          FacturarA: null,
          NomRemitente: null,
          NomDestinatario: null,
          //Search
          IdClie: null,
          IdTercero: null,
          IdTercero1: null,
          IdTercero2: null,
          NomCiaPoliza: null,
          IdTercero3: null,
          NomNit: null,
          CodRuta: null,
          NomRuta: null

        }
      },

      optionsCdRango: [],
      optionsEmbalajes: [],
      optionsIdEmpaque: [],
      optionsIdManejo: [],
      optionsIdMercancia: [],
      optionsIdNaturaleza: [],
      optionsUndMed: [],
      optionsRiesgos: [
        "NORMAL",
        "PERECEDERO",
        "INFLAMABLE",
        "TOXICO",
        "OXIDANTE",
        "EXPLOSIVO",
        "VENENOSO",
        "RADIOACTIVA"
      ],
      optionsCargue: [
        "EMPRESA",
        "CLIENTE",
        "REMITENTE",
        "DESTINATARIO",
        "CONDUCTOR"
      ],
      optionsVigencia: [
        "NORMAL",
        "PERMANENTE"
      ],

      optionsCdTipCarga: [],
      optionsSedeDestinatario: [],
      optionsSedeRemitente: [],
      optionsCdTipoEsc: [],
      optionsCdTipoVehic: [],
      optionsMoneda: [],
      optionsIdVend: [],
      optionsEmbarque: [
        "SUELTA",
        "CONTENEDOR",
        "CUPO",
        "OTRO"
      ],
      optionsModalidad: [
        "ESTANDAR",
        "ESPECIFICO"
      ],
      optionsSeguros: [
        "EMPRESA",
        "CLIENTE"
      ],
      optionsTipoMargen: [
        "UNIDAD",
        "PORCENTAJE(%)"
      ],
      optionsTipoRuta: [
        "NACIONAL",
        "LOCAL"
      ],
      optionsTipoTarifa: [
        "PEDIDO",
        "TABLA",
        "REMESA",
        "CUMPLIDO"
      ],
      optionsTipoTransporte: [
        "GENERAL",
        "PAQUETEO",
        "CONTENEDOR",
        "VARIOSDIA"
      ],
      optionsUndCalcFalt: [
        "PESO",
        "UNIDADES"
      ],
      optionsTipo_Servicio: [
        "EXPRESO",
        "CONSOLIDA_"
      ],
      optionsUndTarifaPago: [
        "PESO",
        "UNIDADES",
        "VOLUMEN"
      ],
      optionsUndVol: [
        {
          code: "m3",
          name: "METRO CÚBICO"
        }
      ],

      optionsCodigoConcepto: [],
      optionsRubro: [
        "CARGOS",
        "DESCUENTOS",
        "TRANSURBANO",
        "CARGUE",
        "DESCARGUE",
        "ESCOLTA",
        "CONTENEDOR",
        "EMBALAJE"
      ],
      optionsTipoConcepto: [
        "CARGO",
        "DESCUENTO"
      ],
      optionsTipoEscolta: [
        {
          code: "0003",
          name: "EN CABINA"
        },
        {
          code: "0002",
          name: "MOTO"
        },
        {
          code: "0001",
          name: "VEHICULAR"
        }
      ],

      searchInputIdMercancia: '',
      optionsCiudad: [],
      searchInputCiudad: '',
      optionsTercero: [],
      searchInputTercero: '',
      optionsIdRuta: [],
      searchInputIdRuta: '',
      optionsIdAgencia: [],
      searchInputIdAgencia: '',
      optionsTercCliente: [],
      searchInputTercCliente: '',
      optionsTarifaComision: [],
      searchInputTarifaComision: ''
    }
  },
  watch: {
    order: function (newVal) { // watch it
      this.localOrder = newVal;
      if(this.DetalleLength > 0) {
        this.detalleItem = JSON.parse(JSON.stringify(this.localOrder.Detalle[0]));
      }
    },
    DetalleLength: function () { // watch it
      this.localOrder = this.order;
      if (this.localOrder.Encabezado.NitCliente !== null) {
        this.AcceptedOrder.getSelectTercAgencias(this.localOrder.Encabezado.NitCliente.IdClie).then(data => {
          this.optionsIdAgencia = data;
        });
      }
      if(this.DetalleLength > 0) {
        this.detalleItem = JSON.parse(JSON.stringify(this.localOrder.Detalle[0]));
      }
      if (this.detalleItem.NitRemitente !== null) {
        this.AcceptedOrder.getSelectTercSedes(this.detalleItem.NitRemitente.IdTercero).then(data => {
          this.optionsSedeRemitente = data;
        });
      }
      if (this.detalleItem.NitDestinatario !== null) {
        this.AcceptedOrder.getSelectTercSedes(this.detalleItem.NitDestinatario.IdTercero).then(data => {
          this.optionsSedeDestinatario = data;
        });
      }
    },
    Volumen: function (newVal) {
      this.detalleItem.Volumen = newVal;
    }
  },
  computed: {
    Volumen(){
      return this.detalleItem.dmsAlto * this.detalleItem.dmsAncho * this.detalleItem.dmsLargo;
    },
    DetalleLength(){
      return this.order.Detalle.length;
    }
  },
  AcceptedOrder: null,
  created() {
    this.localOrder = this.order;
    if(this.DetalleLength > 0) {
      this.detalleItem = JSON.parse(JSON.stringify(this.localOrder.Detalle[0]));
    }
    this.AcceptedOrder = new AcceptedOrder();
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'assets/js/methods.js')
    document.head.appendChild(recaptchaScript)
  },
  mounted() {
    this.AcceptedOrder.getSelects('rango').then(data => {
      this.optionsCdRango = data;
    });

    this.AcceptedOrder.getSelects('embalajesyscom').then(data => {
      this.optionsEmbalajes = data;
    });

    this.AcceptedOrder.getSelects('empaque').then(data => {
      this.optionsIdEmpaque = data;
    });

    this.AcceptedOrder.getSelects('manejo').then(data => {
      this.optionsIdManejo = data;
    });

    this.AcceptedOrder.getSelects('naturaleza').then(data => {
      this.optionsIdNaturaleza = data;
    });

    this.AcceptedOrder.getSelects('undmedsyscom').then(data => {
      this.optionsUndMed = data;
    });

    this.AcceptedOrder.getSelects('carga').then(data => {
      this.optionsCdTipCarga = data;
    });

    this.AcceptedOrder.getSelects('tipoescolta').then(data => {
      this.optionsCdTipoEsc = data;
    });

    this.AcceptedOrder.getSelects('tipovehiculo').then(data => {
      this.optionsCdTipoVehic = data;
    });

    this.AcceptedOrder.getSelects('moneda').then(data => {
      this.optionsMoneda = data;
    });

    this.AcceptedOrder.getSelects('vendedor').then(data => {
      this.optionsIdVend = data;
    });

    this.AcceptedOrder.getSelects('concepto').then(data => {
      this.optionsCodigoConcepto = data;
    });

    var date = new Date();
    this.localOrder.Encabezado.Fecha = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON().slice(0,19);
  },
  methods: {
    watchSelectNitRemitente(){
      this.localOrder.Encabezado.NomRemitente = this.detalleItem.NitRemitente.RazonSocial2;
      this.localOrder.Encabezado.NomContacto = this.detalleItem.NitRemitente.NomContac;
      if (this.detalleItem.NitRemitente.NomContac !== null) {
        this.localOrder.Encabezado.NomContacto = this.detalleItem.NitRemitente.NomContac.NomContac;
        //Telefono
        if (this.detalleItem.NitRemitente.NomContac.TelContac !== null &&
            this.detalleItem.NitRemitente.NomContac.TelContac !== "") {
          this.localOrder.Encabezado.TelContacto = this.detalleItem.NitRemitente.NomContac.TelContac;
        } else {
          this.localOrder.Encabezado.TelContacto = this.detalleItem.NitRemitente.Telefono;
        }
        //Email
        if (this.detalleItem.NitRemitente.NomContac.emlContac !== null &&
            this.detalleItem.NitRemitente.NomContac.emlContac !== "") {
          this.localOrder.Encabezado.emailContac = this.detalleItem.NitRemitente.NomContac.emlContac;
        } else {
          this.localOrder.Encabezado.emailContac = this.detalleItem.NitRemitente.e_mail;
        }
      }

      this.detalleItem.SedeRemitente = this.detalleItem.NitRemitente.Sede;
      if (this.detalleItem.SedeRemitente !== null) {
        this.detalleItem.DirOrigen = this.detalleItem.SedeRemitente.DirSede;
      }
      this.AcceptedOrder.getSelectTercSedes(this.detalleItem.NitRemitente.IdTercero).then(data => {
        this.optionsSedeRemitente = data;
      });
    },
    watchSelectNitDestinatario(){
      this.localOrder.Encabezado.NomDestinatario = this.detalleItem.NitDestinatario.RazonSocial2;
      this.localOrder.Encabezado.NomContactoDest = this.detalleItem.NitDestinatario.NomContac;
      if (this.detalleItem.NitDestinatario.NomContac !== null) {
        this.localOrder.Encabezado.NomContactoDest = this.detalleItem.NitDestinatario.NomContac.NomContac;
        //Telefono
        if (this.detalleItem.NitDestinatario.NomContac.TelContac !== null &&
            this.detalleItem.NitDestinatario.NomContac.TelContac !== "") {
          this.localOrder.Encabezado.TelContactoDest = this.detalleItem.NitDestinatario.NomContac.TelContac;
        } else {
          this.localOrder.Encabezado.TelContactoDest = this.detalleItem.NitDestinatario.Telefono;
        }
        //Email
        if (this.detalleItem.NitDestinatario.NomContac.emlContac !== null &&
            this.detalleItem.NitDestinatario.NomContac.emlContac !== "") {
          this.localOrder.Encabezado.emailContacDest = this.detalleItem.NitDestinatario.NomContac.emlContac;
        } else {
          this.localOrder.Encabezado.emailContacDest = this.detalleItem.NitDestinatario.e_mail;
        }
      }

      this.detalleItem.SedeDestinatario = this.detalleItem.NitDestinatario.Sede;
      if (this.detalleItem.SedeDestinatario !== null) {
        this.detalleItem.DirDestino = this.detalleItem.SedeDestinatario.DirSede;
      }
      this.AcceptedOrder.getSelectTercSedes(this.detalleItem.NitDestinatario.IdTercero).then(data => {
        this.optionsSedeDestinatario = data;
      });
    },
    watchSelectNitCliente(){
      this.localOrder.Encabezado.NomCliente = this.localOrder.Encabezado.NitCliente.NomRepLeg2;
      this.localOrder.Encabezado.nombre_cliente = this.localOrder.Encabezado.NitCliente.NomRepLeg2;
      this.localOrder.Encabezado.FacturarA = this.localOrder.Encabezado.NitCliente.RazonSocialFact;
      if (this.localOrder.Encabezado.NitCliente.RazonSocialFact !== null) {
        this.localOrder.Encabezado.FacturarA = [this.localOrder.Encabezado.NitCliente.RazonSocialFact.IdTercero, this.localOrder.Encabezado.NitCliente.RazonSocialFact.RazonSocial].join(' ');
      }
      this.localOrder.Encabezado.IdAgencia = this.localOrder.Encabezado.NitCliente.Agencia;
      this.AcceptedOrder.getSelectTercAgencias(this.localOrder.Encabezado.NitCliente.IdClie).then(data => {
        this.optionsIdAgencia = data;
      });
      if (this.localOrder.Encabezado.NitCliente.idVend !== null) {
        this.localOrder.Encabezado.IdVend = this.localOrder.Encabezado.NitCliente.idVend;
      }
      this.CdCms = this.localOrder.Encabezado.NitCliente.CdCms
    },
    watchSelectIdMercancia(){
      this.detalleItem.DescripMcias = this.detalleItem.IdMercancia.DescripMcia2;
    },
    watchSelectIdRuta(){
      this.localOrder.Encabezado.NomRuta = this.localOrder.Encabezado.IdRuta.Ruta;
      //
      this.detalleItem.CiudadOrigen = this.localOrder.Encabezado.IdRuta.IdLocOri;
      this.detalleItem.CiudadDestino = this.localOrder.Encabezado.IdRuta.IdLocDes;
    },
    watchSelectCodigoConcepto(key) {
      if (this.localOrder.Concepto[key].CodigoConcepto !== null) {
        this.localOrder.Concepto[key].Descripcion = this.localOrder.Concepto[key].CodigoConcepto.Concepto2;
      }
    },
    watchSelectRubro(){
      var VrCargos = 0;
      var VrDctos = 0;
      var VrTraUrbano = 0;
      var VrCargue = 0;
      var VrDesCargue = 0;
      var VrEscolta = 0;
      var VrDevolucionContdor = 0;
      var VrEmbalajes = 0;

      for (const i in this.localOrder.Concepto) {
        switch (this.localOrder.Concepto[i].Rubro) {
          case "CARGOS":
            VrCargos += this.localOrder.Concepto[i].Cantidad * this.localOrder.Concepto[i].VrUnitario;
            break;
          case "DESCUENTOS":
            VrDctos += this.localOrder.Concepto[i].Cantidad * this.localOrder.Concepto[i].VrUnitario;
            break;
          case "TRANSURBANO":
            VrTraUrbano += this.localOrder.Concepto[i].Cantidad * this.localOrder.Concepto[i].VrUnitario;
            break;
          case "CARGUE":
            VrCargue += this.localOrder.Concepto[i].Cantidad * this.localOrder.Concepto[i].VrUnitario;
            break;
          case "DESCARGUE":
            VrDesCargue += this.localOrder.Concepto[i].Cantidad * this.localOrder.Concepto[i].VrUnitario;
            break;
          case "ESCOLTA":
            VrEscolta += this.localOrder.Concepto[i].Cantidad * this.localOrder.Concepto[i].VrUnitario;
            break;
          case "CONTENEDOR":
            VrDevolucionContdor += this.localOrder.Concepto[i].Cantidad * this.localOrder.Concepto[i].VrUnitario;
            break;
          case "EMBALAJE":
            VrEmbalajes += this.localOrder.Concepto[i].Cantidad * this.localOrder.Concepto[i].VrUnitario;
            break;
        }
      }

      this.localOrder.Encabezado.VrCargos = VrCargos;
      this.localOrder.Encabezado.VrDctos = VrDctos;
      this.localOrder.Encabezado.VrTraUrbano = VrTraUrbano;
      this.localOrder.Encabezado.VrCargue = VrCargue;
      this.localOrder.Encabezado.VrDesCargue = VrDesCargue;
      this.localOrder.Encabezado.VrEscolta = VrEscolta;
      this.localOrder.Encabezado.VrDevolucionContdor = VrDevolucionContdor;
      this.localOrder.Encabezado.VrEmbalajes = VrEmbalajes;

    },
    async hanldeSearchInputIdMercancia(event) {
      this.searchInputIdMercancia = event.query.trim();
      this.optionsIdMercancia = await this.AcceptedOrder.getSearchSelects('tipomercancia', this.searchInputIdMercancia).then(data => {
        return data;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
            return [];
          });
    },
    async hanldeSearchInputCiudad(event) {
      this.searchInputCiudad = event.query.trim();

      this.optionsCiudad = await this.AcceptedOrder.getSearchSelects('ciudad', this.searchInputCiudad).then(data => {
        return data;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
            return [];
          });
    },
    async hanldeSearchInputTercero(/*event*/tipo) {
      //this.searchInputTercero = event.query.trim();

      // this.optionsTercero = await this.AcceptedOrder.getSearchSelects('tercerosyscom', this.searchInputTercero).then(data => {
      //   return data;
      // })
      switch (tipo) {
        case 1:
          this.searchInputTercero = this.localOrder.Encabezado.IdTercero;
          break;

        case 2:
          this.searchInputTercero = this.localOrder.Encabezado.IdTercero1;
          break;

        case 3:
          this.searchInputTercero = this.localOrder.Encabezado.IdTercero2;
          break;

        case 4:
          this.searchInputTercero = this.localOrder.Encabezado.IdTercero3;
          break;
      }
      this.loadingSearch[tipo] = true;
      await this.AcceptedOrder.getSearchSelects('tercerosyscom', this.searchInputTercero).then(data => {
        this.loadingSearch[tipo] = false;
        switch (tipo) {
          case 1:
            this.detalleItem.NitRemitente = data;
            this.watchSelectNitRemitente();
            break;

          case 2:
            this.detalleItem.NitDestinatario = data;
            this.watchSelectNitDestinatario();
            break;

          case 4:
            this.localOrder.Concepto[0].Nit = data;
            this.localOrder.Encabezado.NomNit = data.RazonSocial2;
            break;

          case 3:
            this.localOrder.Encabezado.NitCiaPoliza = data;
            this.localOrder.Encabezado.NomCiaPoliza = data.RazonSocial2;
            break;
        }
      })
          .catch(() => {
            this.loadingSearch[tipo] = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
            // return [];
          });
    },
    async hanldeSearchInputIdRuta(/*event*/) {
      //this.searchInputIdRuta = event.query.trim();

      //this.optionsIdRuta = await this.AcceptedOrder.getSearchSelects('rutasyscom', this.searchInputIdRuta).then(data => {
      //  return data;
      //})
      this.loadingSearch[5] = true;
      await this.AcceptedOrder.getSearchSelects('rutasyscom', this.localOrder.Encabezado.CodRuta).then(data => {
        this.loadingSearch[5] = false;
        this.localOrder.Encabezado.IdRuta = data;
        this.watchSelectIdRuta();
      })
          .catch(() => {
            this.loadingSearch[0] = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
            //return [];
          });
    },
    async hanldeSearchInputIdAgencia(event) {
      this.searchInputIdAgencia = event.query.trim();

      this.optionsIdAgencia = await this.AcceptedOrder.getSearchSelects('agencia', this.searchInputIdAgencia).then(data => {
        return data;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
            return [];
          });
    },
    async hanldeSearchInputTercCliente(/*event*/) {
      //this.searchInputTercCliente = event.query.trim();

      // this.optionsTercCliente = await this.AcceptedOrder.getSearchSelects('clientesyscom', this.searchInputTercCliente).then(data => {
      //   return data;
      // })
      this.loadingSearch[0] = true;
      await this.AcceptedOrder.getSearchSelects('clientesyscom', this.localOrder.Encabezado.IdClie).then(data => {
        this.loadingSearch[0] = false;
        this.localOrder.Encabezado.NitCliente = data;
        this.watchSelectNitCliente();
      })
          .catch(() => {
            this.loadingSearch[0] = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
            // return [];
          });
    },
    async hanldeSearchInputTarifaComision(event) {
      this.searchInputTarifaComision = event.query.trim();

      this.optionsTarifaComision = await this.AcceptedOrder.getSearchSelectTablaPor(this.searchInputTarifaComision, this.CdCms).then(data => {
        return data;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
            return [];
          });
    },
    saveOrder(){
      this.localOrder.Detalle = [];
      this.localOrder.Detalle.push(JSON.parse(JSON.stringify(this.detalleItem)));
      this.$emit('save-order',this.localOrder);
    },
    saveSyscom(){
      this.localOrder.Detalle = [];
      this.localOrder.Detalle.push(JSON.parse(JSON.stringify(this.detalleItem)));
      this.$emit('save-syscom',this.localOrder);
    },
  }
}
</script>

<style>
  .p-component{
    font-size: 0.875rem !important;
  }
  .p-component-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
  }
</style>